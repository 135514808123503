import React from "react"
import { Helmet } from "react-helmet"

export default function Toptier({ location, data }) {
  return (
    <>
      <Helmet title="Ecomaxx | Toptier">
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="stylesheet"
          href="/toptier/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossorigin="anonymous"
        />
        <link rel="stylesheet" href="/toptier/css/styles.css" />
      </Helmet>

      <header class="header">
        <nav class="navbar">
          <a class="logo" href="/">
            EcoMaxx
          </a>
          <a class="btn" href="/toptier/en">
            ENGLISH
          </a>
        </nav>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h1>El más alto estándar de limpieza en tu motor</h1>
            </div>
          </div>
        </div>
      </header>
      <section class="intro text-center py-5">
        <div class="container">
          <img
            class="pb-5"
            src="/toptier/images/logo-toptier.svg"
            alt="TOP TIER - Detergent Gasoline"
          />
          <h2>
            EcoMaxx<sup>®</sup> es una marca de gasolina con certificación TOP
            TIER™, que es reconocida como una especificación de combustible de
            primer nivel desarrollada y aplicada por los principales fabricantes
            de automóviles y equipos pesados.
          </h2>
        </div>
      </section>
      <section class="tabla pt-5">
        <div class="container">
          <p class="text-center">
            TOP TIER™ es recomendada por los siguientes:
          </p>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>Audi</td>
                <td>BMW</td>
                <td>GM</td>
                <td>FCA</td>
              </tr>
              <tr>
                <td>Ford</td>
                <td>Honda</td>
                <td>Toyota</td>
                <td>Mercedes-Benz</td>
              </tr>
              <tr>
                <td>Navistar</td>
                <td>VW</td>
                <td>Detroit Diesel</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section class="cards pt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="card h-100 text-center">
                <div class="card-header">
                  <h3>Aprobaciones</h3>
                </div>
                <div class="card-body">
                  <img
                    class="py-3"
                    src="/toptier/images/icon-approvals.svg"
                    alt="Aprobaciones"
                  />
                  <p>
                    EcoMaxx<sup>®</sup> tiene el detergente aditivo PowerMaxx
                    <sup>®</sup>.
                  </p>
                  <p>
                    PowerMaxx<sup>®</sup> es un detergente certificado que
                    cumple con los requisitos de la Agencia de Protección
                    Ambiental de los Estados Unidos para la más baja
                    concentración de aditivos (LAC) a 24 ptb. (40CFR parte 80
                    subparte G)
                  </p>
                  <p>
                    El PowerMaxx<sup>®</sup> cumple con los requisitos de
                    aditivos de control de depósitos de gasolina de TOP TIER™,
                    tal y como se indica en la norma de control de depósitos de
                    TOP TIER™ a una tasa de tratamiento de 58 ptb.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 pt-5 pt-md-0">
              <div class="card h-100 text-center">
                <div class="card-header">
                  <h3>Aplicación</h3>
                </div>
                <div class="card-body">
                  <img
                    class="py-3"
                    src="/toptier/images/icon-application.svg"
                    alt="Aplicación"
                  />
                  <p>
                    PowerMaxx<sup>®</sup> es un detergente sintético multiuso
                    que confiere a la gasolina las siguientes propiedades:
                  </p>
                  <ul class="text-left">
                    <li>Control de depósito de la válvula de admisión</li>
                    <li>Control de depósitos en la cámara de combustión</li>
                    <li>
                      La inyección directa de gasolina (GDI) mantiene la
                      limpieza y el rendimiento de la limpieza
                    </li>
                    <li>Detergencia PFI</li>
                    <li>Protección contra el óxido</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ position: "static !important" }}></div>
      <section class="propiedades py-5">
        <div class="container">
          <div class="content py-5 px-3">
            <h3 class="text-center pb-3">Propiedades típicas</h3>
            <div class="propiedad">
              <p>Apariencia</p>
              <p>Líquido claro</p>
            </div>
            <div class="propiedad">
              <p>Gravedad específica, 0/60°F (15.6/15.6°C)</p>
              <p>0.868</p>
            </div>
            <div class="propiedad">
              <p>Densidad, lb/gal, 60°F, (15.6°C)</p>
              <p>724</p>
            </div>
            <div class="propiedad">
              <p>Punto de inflamación, PMCC, °F (°C)</p>
              <p>101 (38.3)</p>
            </div>
            <div class="propiedad">
              <p>Punto de fluidez, °F (°C)</p>
              <p>&lt;-40 (&lt;-40)</p>
            </div>
            <div class="propiedad">
              <p>Viscosidad, cSt @</p>
              <div class="propiedad">
                <p>104°F (40°C)</p>
                <p>14.0</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>-4°F (-20°C)</p>
                <p>217</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>-22°F (-30°C)</p>
                <p>264</p>
              </div>
            </div>
            <div class="propiedad mt-3">
              <p>Presión de vapor, psi @</p>
              <div class="propiedad">
                <p>77°F (25°C)</p>
                <p>0.029</p>
              </div>
            </div>
            <div class="propiedad">
              <p></p>
              <div class="propiedad">
                <p>120°F (49°C)</p>
                <p>0.1</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer text-center">
        <img src="/toptier/images/logo-ecomaxx.svg" alt="Ecomaxx" />
      </footer>

      <script
        src="/toptier/js/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossorigin="anonymous"
      ></script>
      <script
        src="/toptier/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"
      ></script>
      <script src="/toptier/js/scripts.js"></script>
    </>
  )
}
